<template>
	<!-- 404 -->
	<div class="default-page">
		<img src="@/assets/image/default/errorText.png">
		<a href="/">返回首页</a>
	</div>
</template>

<script>
export default {
	data () {
		return {
		}
	},
	methods: {

	},
}
</script>

<style lang="scss" scoped>
.default-page {
	position: relative;
	@include wh(100vw, 100vh);

	text-align: center;
	margin: 0px auto;
	min-width: 1020px;
	overflow-x: hidden;
	background-color: #1f6e8e;
	overflow: hidden;
	background-repeat: no-repeat;

	background-position: center top;

	img {
		height: auto;
		margin: 0px auto;
		margin-top: 158px;
		display: block
	}

	a {
		font-size: 20px;
		text-decoration: underline;
		color: #fff;
		margin-top: 60px;
		display: block
	}
}
</style>
